import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { capitalize } from '_Utils/strings/stringsUtils';
import Icon from '_Components/Icons/Icon';
import { allAxisColors } from '_Resources/Triggers/Services/helpers';
import { white } from 'styles/abstracts/colors';
import { getHeaderTopHeight, getSubHeaderHeight } from '_Layouts/Header/_Utils/HeaderUtils';
import TriggersColumn from './TriggersColumn';

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fill-available;
  position: relative;
  overflow-y: auto;
`;

const TriggersHeaderContainer = styled.div`
  width: fill-available;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  background-color: ${white};
  position: sticky;
  top: 0;
  left: 0;
`;

const TriggerColumnTitle = styled.div<{ columnColor: string }>`
  display: flex;
  align-items: center;
  color: ${({ columnColor }) => columnColor};
  font-weight: bold;
  font-size: 1.2rem;
  height: 2rem;
  border-bottom: 3px solid ${({ columnColor }) => columnColor};
  width: fill-available;
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  justify-content: center;
`;

const TriggersColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  gap: 1rem;
`;

type TriggersDataContainerType = {
  data: {
    color: string;
    originalColor: string;
    name: string;
    originalName: string;
    items: {
      name: string;
      originalName: string;
      percentage: number;
      value: number;
    }[];
    value: number;
    deleted: boolean;
    masked: boolean;
  }[];
  t: Function;
};

const TriggersDataContainer = ({ data, t }: TriggersDataContainerType) => {
  const [dataContainerMaxHeight, setDataContainerMaxHeight] = useState(0);

  useEffect(() => {
    const headertTopHeight = getHeaderTopHeight() || 0;
    const subHeadertHeight = getSubHeaderHeight() || 0;
    const updatedDataContainerMaxHeight = window.innerHeight - headertTopHeight - subHeadertHeight - 5;

    setDataContainerMaxHeight(updatedDataContainerMaxHeight);
  });

  return (
    <DataContainer style={{ maxHeight: dataContainerMaxHeight }}>
      <TriggersHeaderContainer>
        {data?.length > 0 &&
          data.map(data => (
            <TriggerColumnTitle columnColor={data.color}>
              {allAxisColors[data.originalName]?.icon && (
                <Icon icon={allAxisColors[data.originalName]?.icon || null} color={data.color} size={24} />
              )}
              <Title>{t(`card:${capitalize(data.name)}`)}</Title>
            </TriggerColumnTitle>
          ))}
      </TriggersHeaderContainer>
      <TriggersColumnsContainer>
        {data?.length > 0 && data.map(triggersColumn => <TriggersColumn columnData={triggersColumn} />)}
      </TriggersColumnsContainer>
    </DataContainer>
  );
};

export default withTranslation('triggers', 'card')(TriggersDataContainer);
