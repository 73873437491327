export const ADD_TRIGGERS_FILTERS = 'ADD_TRIGGERS_FILTERS';

export const addTriggersFilters = (context, filterType, filterValues) => {
  return {
    type: ADD_TRIGGERS_FILTERS,
    context,
    filterType,
    filterValues,
  };
};
