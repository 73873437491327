import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import ProjectConfigurationCardBody from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/ProjectConfigurationCardBody';
import ListUseCasesIndustries from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/ListUseCasesIndustries/ListUseCasesIndustries';
import ListAvailableSources from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/ListAvailableSources/ListAvailableSources';
import SourcesConfiguration from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/SourcesConfiguration/SourcesConfiguration';
import DateConfiguration from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/DateConfiguration/DateConfiguration';
import CreditEstimation from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/CreditEstimation/CreditEstimation';
import DataConfiguration from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/DataConfiguration/DataConfiguration';
import ProcessingAutomation from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/ProcessingAutomation/ProcessingAutomation';
import ConfigurationValidation from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/ConfigurationValidation/ConfigurationValidation';
import ProjectNaming from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/ProjectNaming/ProjectNaming';
import ProcessingStep from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/ProcessingStep/ProcessingStep';
import { CARD_STEPS } from 'Routes/Settings/ProjectConfiguration/utils';

const BodyContainer = styled.div`
  padding: 0.5em;
`;

const BodyContent = ({ currentCardStep, bodyProps, t }) => {
  switch (CARD_STEPS[Object.keys(CARD_STEPS)[currentCardStep]]) {
    case CARD_STEPS.INDUSTRIES:
    case CARD_STEPS.USE_CASES:
      return (
        <ProjectConfigurationCardBody>
          <ListUseCasesIndustries
            isUseCase={CARD_STEPS[Object.keys(CARD_STEPS)[currentCardStep]] === CARD_STEPS.USE_CASES}
            activeStep={CARD_STEPS[Object.keys(CARD_STEPS)[currentCardStep]]}
            setIndustry={bodyProps.setIndustry}
            setUseCase={bodyProps.setUseCase}
            industry={bodyProps.industry}
            useCase={bodyProps.useCase}
          />
        </ProjectConfigurationCardBody>
      );
    case CARD_STEPS.SOURCES_LIST:
      return (
        <ProjectConfigurationCardBody>
          <ListAvailableSources
            selectedSources={bodyProps.selectedSources}
            setSelectedSources={bodyProps.setSelectedSources}
          />
        </ProjectConfigurationCardBody>
      );
    case CARD_STEPS.SOURCES_CONFIGURATION:
      return (
        bodyProps.selectedSources[0].name !== 'File upload' && (
          <ProjectConfigurationCardBody>
            <SourcesConfiguration
              selectedSources={bodyProps.selectedSources}
              setSources={bodyProps.setSources}
              sources={bodyProps.sources}
              t={t}
            />
          </ProjectConfigurationCardBody>
        )
      );
    case CARD_STEPS.DATE_CONFIGURATION:
      return (
        <>
          {/* <BodyContainer>
            <DateConfiguration
              setStartDate={bodyProps.setStartDate}
              startDate={bodyProps.startDate}
              t={t}
            />
          </BodyContainer> */}
          <ProjectConfigurationCardBody transparent>
            <CreditEstimation range={bodyProps.extraction} sources={bodyProps.selectedSources} t={t} />
          </ProjectConfigurationCardBody>
        </>
      );
    case CARD_STEPS.DATA_CONFIGURATION:
      return (
        <ProjectConfigurationCardBody>
          <DataConfiguration
            settings={bodyProps.settings}
            setSettings={bodyProps.setSettings}
            selectedSources={bodyProps.selectedSources}
            lang={bodyProps.lang}
            setLang={bodyProps.setLang}
            t={t}
          />
        </ProjectConfigurationCardBody>
      );
    case CARD_STEPS.AUTOMATION:
      return (
        <BodyContainer>
          <ProcessingAutomation
            setAutomation={bodyProps.setAutomation}
            automation={bodyProps.automation}
            automationFrequency={bodyProps.automationFrequency}
            setAutomationFrequency={bodyProps.setAutomationFrequency}
            t={t}
          />
        </BodyContainer>
      );
    case CARD_STEPS.VALIDATION:
      return (
        <ProjectConfigurationCardBody>
          <ConfigurationValidation
            sources={bodyProps.sources}
            selectedSources={bodyProps.selectedSources}
            startDate={bodyProps.startDate}
            settings={bodyProps.settings}
            lang={bodyProps.lang}
            t={t}
          />
        </ProjectConfigurationCardBody>
      );
    case CARD_STEPS.PROJECT_NAMING:
      return (
        <BodyContainer>
          <ProjectNaming
            name={bodyProps.projectName}
            setName={bodyProps.setProjectName}
            sendEmail={bodyProps.emailEndProcessing}
            checkSendEmail={bodyProps.checkEmailEndProcessing}
            t={t}
          />
        </BodyContainer>
      );
    case CARD_STEPS.PROCESSING:
      return (
        <BodyContainer>
          <ProcessingStep t={t} />
        </BodyContainer>
      );
    default:
      return null;
  }
};

export default withTranslation(['card', 'button'])(BodyContent);
