import React from 'react';
import styled from 'styled-components';
import { grayShades } from 'styles/abstracts/colors';
import TriggerItem from './TriggerItem';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 15rem;
`;

const ItemListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${grayShades.g800};
`;

type TriggersColumnType = {
  columnData: {
    color: string;
    deleted: boolean;
    items: {
      name: string;
      originalName: string;
      percentage: number;
      value: number;
    }[];
    masked: boolean;
    name: string;
    originalColor: string;
    originalName: string;
    value: number;
  };
};

const TriggersColumn = ({ columnData }: TriggersColumnType) => {
  const { items } = columnData;

  return (
    <Column>
      {items && (
        <ItemListContainer>
          {items.map(item => (
            <TriggerItem item={item} />
          ))}
        </ItemListContainer>
      )}
    </Column>
  );
};

export default TriggersColumn;
