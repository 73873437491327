import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { radius } from '../../../styles/abstracts/variables';
import { grayShades, themeColors } from '../../../styles/abstracts/colors';

const Box = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: ${({ selected }) => (selected ? 'flex-end' : 'flex-start')};
  width: 2.25em;
  padding: 0.15em;
  border-radius: ${radius.round};
  background-color: ${({ selected, staticColor }) => (selected || staticColor ? themeColors.primary : grayShades.g500)};
  cursor: pointer;
  transition: all 0.5s;
`;

const Label = styled.label`
  margin: 0 0.5em;
  color: ${({ labelColor }) => labelColor || 'inherit'} !important;
  white-space: nowrap;
`;

const SwitchArea = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: ${({ label, customMargin }) => customMargin || `0.75em ${label ? '0.5em' : '0'}`};
`;

const Button = styled.div`
  width: 1.1em;
  height: 1.1em;
  border-radius: ${radius.round};
  background-color: white;
`;

const QBooleanSwitch = props => {
  const { label, customMargin, id, labelColor, value, onChanged, staticColor } = props;
  return (
    <SwitchArea label={label} customMargin={customMargin} id={id}>
      {label && <Label labelColor={labelColor}>{label}</Label>}
      <Box selected={value} onClick={onChanged} staticColor={staticColor}>
        <Button />
      </Box>
    </SwitchArea>
  );
};

QBooleanSwitch.propTypes = {
  id: PropTypes.number,
  value: PropTypes.bool.isRequired,
  onChanged: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  customMargin: PropTypes.string,
  staticColor: PropTypes.bool,
};

QBooleanSwitch.defaultProps = {
  id: null,
  label: null,
  labelColor: null,
  customMargin: null,
  staticColor: false,
};

export default QBooleanSwitch;
