import React from 'react';
import DataConfigurationSummary from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/ConfigurationValidation/_Components/DataConfigurationSummary/DataConfigurationSummary';
import {
  Section,
  SectionTitle,
  SectionLink,
  SectionTitleContainer,
} from '_Cards/SettingsCards/ProjectDetailsCard/_Components/GlobalInformations/styles';

const preferDate = true;
const satMark = 10;
const recoMark = false;

const DataSettingsSection = ({ setActiveTab }) => (
  <Section>
    <SectionTitleContainer>
      <SectionTitle>Data settings</SectionTitle>
      <SectionLink onClick={() => setActiveTab('automation')}>Edit configuration</SectionLink>
    </SectionTitleContainer>
    <DataConfigurationSummary recoMark={recoMark} satMark={satMark} preferDate={preferDate} />
  </Section>
);

export default DataSettingsSection;
