import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import { MenuItemLabel } from 'Routes/Insights/InsightsWall/_Containers/InsightWallGrid/_Components/InsightModeMenu/_Components/InsightModeMenuOption';
import { RadioButton, CheckboxContainer } from '_Charts/LineCharts/CLineChart/CLineChartSettings';
// eslint-disable-next-line import/no-cycle
import QModal from '_Components/Modals/QModal/QModal';
import QButton from '_Components/Buttons/QButton/QButton';
import { withTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  font-weight: bold;
`;

const ExportTriggersDataModal = ({
  t,
  setMessage,
  isOpen,
  open,
  id,
  query,
  client,
  activeTriggersOnlySelectedThematics,
}) => {
  const [chosenFormat, setChosenFormat] = useState('csv');

  const footer = (
    <div>
      <QButton
        onClick={async () => {
          const { data } = await client.query({
            query,
            fetchPolicy: 'no-cache',
            variables: {
              id,
              fileExtension: chosenFormat,
              applyThematicSelection: activeTriggersOnlySelectedThematics,
            },
          });
          setMessage && setMessage(data[Object.keys(data)[0]]);
          open(!isOpen);
        }}
      >
        {t('button:Export')}
      </QButton>
    </div>
  );

  return (
    <QModal
      isOpen={isOpen}
      title="button:Export data"
      onClose={() => open(!isOpen)}
      size="md"
      footer={footer}
      config={{ mentions: false, date: false }}
    >
      <b>{t('card:Choose your file format')}</b>
      <br />
      <Container>
        <CheckboxContainer onClick={() => setChosenFormat('csv')}>
          <MenuItemLabel checked={chosenFormat === 'csv'}>
            <RadioButton
              type="radio"
              checked={chosenFormat === 'csv'}
              value="csv"
              onChange={() => setChosenFormat('csv')}
            />
          </MenuItemLabel>
          CSV
        </CheckboxContainer>
        <CheckboxContainer onClick={() => setChosenFormat('xlsx')}>
          <MenuItemLabel checked={chosenFormat === 'xlsx'}>
            <RadioButton
              type="radio"
              checked={chosenFormat === 'xlsx'}
              value="xlsx"
              onChange={() => setChosenFormat('xlsx')}
            />
          </MenuItemLabel>
          XLSX
        </CheckboxContainer>
      </Container>
      <br />
    </QModal>
  );
};

ExportTriggersDataModal.propTypes = {
  t: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  query: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.any.isRequired,
  activeTriggersOnlySelectedThematics: PropTypes.bool.isRequired,
};

export default withTranslation()(withApollo(ExportTriggersDataModal));
