import produce from 'immer';
import { TEMPORARY_STORE_TO_WATCH } from 'Routes/Insights/Triggers/_Components/configs/globalConfig';
import { FILTERS_REMOVE_STRATEGIES } from '_Utils/filters/filterUtils';
import { rawFilterValues } from '_Utils/filters/rawFilterValues';
import { cloneDeep, remove, isEqual } from 'lodash';
import { mergeThematics } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/FiltersUtils/ThematicsUtils';
import { isValidCriteriaKeys } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/FiltersUtils/CriteriaKeysUtils';

const mergeFiltersArray = (initialSettings, filterType, filterValues, globalFilters) => {
  const existingFilters = initialSettings[filterType] || [];
  const mergedFilters = [...existingFilters, ...rawFilterValues(filterValues)];
  return produce(initialSettings, newSettings => {
    newSettings[filterType] = mergedFilters;
  });
};

export const addTriggersFilters = (state, { context, filterType, filterValues }) => {
  const storeToWatch = TEMPORARY_STORE_TO_WATCH[context].temporarySettingsField;

  const currentFilters = cloneDeep(state[storeToWatch].settings[filterType]);
  const currentSettings = state[storeToWatch].settings;

  const newSettings = mergeFiltersArray(currentSettings, filterType, filterValues, currentFilters);

  return produce(state, newState => {
    newState[storeToWatch].settings = newSettings;
  });
};

const removeFiltersFromArray = (
  initialSettings,
  { filterType, filterValues: [[filterValue], parentKey] },
  globalSettings,
) => {
  const removingStrategy = FILTERS_REMOVE_STRATEGIES[filterType] || FILTERS_REMOVE_STRATEGIES.default;

  const existingFilters = initialSettings[filterType] || [];
  const removedFiltersArray = removingStrategy(existingFilters, filterValue, parentKey);
  return produce(initialSettings, newSettings => {
    newSettings[filterType] = removedFiltersArray;
  });
};

export const removeTriggersFilters = (state, { context, filterType, filterValues }) => {
  const storeToWatch = TEMPORARY_STORE_TO_WATCH[context].temporarySettingsField;
  const currentSettings = state[storeToWatch].settings;
  const newSettings = removeFiltersFromArray(
    currentSettings,
    {
      filterType,
      filterValues,
    },
    state.temporaryTriggers.settings[filterType],
  );
  return produce(state, newState => {
    newState[storeToWatch].settings = newSettings;
  });
};

export const updateFilters = (state, { filters }) => {
  if (!filters) return { ...state };
  const normal = filters.normal && rawFilterValues(filters.normal);
  const related = filters.related && rawFilterValues(filters.related);
  const thematic = filters.thematic && rawFilterValues(filters.thematic);
  const keywords = filters.keywords && rawFilterValues(filters.keywords);
  const cardsStore =
    state.activeBenchmark && state.activeBenchmark.settings && cloneDeep(state.activeBenchmark.settings.cards);
  const columnsStore =
    state.activeBenchmark && state.activeBenchmark.settings && cloneDeep(state.activeBenchmark.settings.columns);
  return produce(state, newState => {
    if (newState.activeBenchmark) {
      newState.activeBenchmark.settings.globalSettings.filters.normal = normal;
      newState.activeBenchmark.settings.globalSettings.filters.thematic = thematic;
      newState.activeBenchmark.settings.globalSettings.filters.related = related;
      newState.activeBenchmark.settings.globalSettings.filters.keywords = keywords;
    }
    const mergedThematics = item =>
      mergeThematics(item.settings.filters.thematic, newState.activeBenchmark.settings.globalSettings.filters.thematic);
    cardsStore &&
      cardsStore.forEach((card, index) => {
        newState.activeBenchmark.settings.cards[index].mergedSettings.filters.thematic = mergedThematics(card);
        newState.activeBenchmark.settings.cards[index].mergedSettings.filters.normal = isValidCriteriaKeys(
          card.settings.filters.normal,
          normal,
        );
      });
    columnsStore &&
      columnsStore.forEach((column, index) => {
        newState.activeBenchmark.settings.columns[index].mergedSettings.filters.thematic = mergedThematics(column);
        newState.activeBenchmark.settings.columns[index].mergedSettings.filters.normal = isValidCriteriaKeys(
          column.settings.filters.normal,
          normal,
        );
      });
  });
};
