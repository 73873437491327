import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { red } from 'styles/abstracts/colors';
import DataAutomationSettings from './_Containers/DataAutomationSettings/DataAutomationSettings';
import DataContentSettings from './_Containers/DataContentSettings/DataContentSettings';
import AutomationFooter from './_Components/AutomationFooter/AutomationFooter';

const Automation = ({ settings, automation, projectId, slug, t }) => {
  const [dataSettings, setSettings] = useState({
    automation,
    // dataConfiguration: settings,
  });

  const ErrorMessage = styled.p`
    width: 100%;
    text-align: center;
    color: ${red};
    font-weight: bold;
  `;

  const [error, setError] = useState(false);

  useEffect(() => {
    setSettings({ automation });
  }, [automation]);

  return (
    <>
      <DataAutomationSettings automationSettings={dataSettings?.automation} setSettings={setSettings} t={t} />
      {settings && <DataContentSettings settings={dataSettings} setSettings={setSettings} t={t} />}
      {error && <ErrorMessage>{t('An error occurred')}</ErrorMessage>}
      <AutomationFooter settings={dataSettings} projectId={projectId} slug={slug} t={t} setError={setError} />
    </>
  );
};

export default withTranslation('card', 'button')(Automation);
