import gql from 'graphql-tag';

const exportEmotionalTriggersTableQuery = gql`
  query exportEmotionalTriggersTable($id: Int!, $fileExtension: String!, $applyThematicSelection: Boolean) {
    exportEmotionalTriggersTable(
      id: $id
      fileExtension: $fileExtension
      applyThematicSelection: $applyThematicSelection
    ) {
      code
      message
    }
  }
`;

export default exportEmotionalTriggersTableQuery;
