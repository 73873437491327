import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QTitle from '_Components/Texts/QTitle/QTitle';
import DatePickerTriggers from './DatePickerTriggers';


class DatePickerContainer extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    title: PropTypes.string,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
  };

  static defaultProps = {
    title: null,
    marginLeft: '2em',
    marginRight: '0',
  };

  render() {
    const { t, title } = this.props;

    return (
      <>
        <QTitle>
          <QTitle className="Title">{t(title)}</QTitle>
        </QTitle>
        <DatePickerTriggers {...this.props} />
      </>
    );
  }
}

export default withTranslation('header')(DatePickerContainer);
