import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';
import { get, uniq } from 'lodash';
import MutationSaveTriggersTable from '_Resources/Triggers/Services/MutationSaveTriggersTable';
import { spacing } from 'styles/abstracts/variables';
import { grayShades } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import QInput from '_Components/Fields/QInput/QInput';
import QModalBody from '_Components/Modals/QModalBody/QModalBody';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QButton from '_Components/Buttons/QButton/QButton';
import QModal from '_Components/Modals/QModal/QModal';
import { setTriggersTableName } from '_Resources/Triggers/Actions/Filters/setTriggersTableName';
import { setTriggersTableDescription } from '_Resources/Triggers/Actions/Filters/setTriggersTableDescription';
import {
  getAnalysisAxisOptions,
  getCalculationCriteriaOptions,
  getItemsToDisplayOptions,
  getStateItemsToDisplay,
} from '_Resources/Triggers/Services/helpers';

const StyledQModalBody = styled(QModalBody)`
  padding: 0 ${spacing.medium};
`;

const StyledQInput = styled(QInput)`
  width: 100%;
`;

const StyledTextArea = styled(QInput)`
  width: 100%;
  resize: none;
`;

const StyledLabel = styled(Label)`
  color: ${grayShades.g800};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Cancel = styled.p`
  color: ${grayShades.g800};
  font-size: ${fontSizes.smaller};
  cursor: pointer;
  font-weight: bold;
`;

const CreateButton = styled(QButton)`
  margin: 0 ${spacing.xsmall} 0 ${spacing.large} !important;
`;

const Description = styled(QTitle)`
  font-size: 1em;
  padding: 0;
  margin: 0;
`;

class TriggersRenameModal extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    triggersTable: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        analysisAxis: PropTypes.string,
        period: PropTypes.shape({
          startDate: PropTypes.string,
          endDate: PropTypes.string,
        }),
        isDefault: PropTypes.bool.isRequired,
        settings: PropTypes.array,
      }),
    ),
    footer: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    currentProject: PropTypes.shape.isRequired,
  };

  static defaultProps = {
    triggersTable: null,
  };

  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      triggersTableName: get(this.props.triggersTable, 'title') || '',
      triggersTableDescription: get(this.props.triggersTable, 'description') || '',
      triggersTableAnalysisAxis: get(this.props.triggersTable, ['settings', 'analysisAxis']) || '',
      triggersTableItemsToDisplay: get(this.props.triggersTable, ['settings', 'itemsToDisplay']) || '',
      triggersTableCalculationCriteria: get(this.props.triggersTable, ['settings', 'calculationCriteria']) || '',
      triggersTableSignificanceThreshold: get(this.props.triggersTable, ['settings', 'significanceThreshold']) || '',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
  }

  onSubmit() {
    const { toggle } = this.props;
    toggle();
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void {
    const { t, dispatchSetTriggersTableName, dispatchSetTriggersTableDescription, criteriaKeysOptions } = this.props;
    if (
      prevProps?.triggersTable?.title !== this.props?.triggersTable?.title &&
      this.props?.triggersTable?.title !== undefined
    ) {
      this.setState({
        triggersTableName: this.props.triggersTable.title,
      });
      dispatchSetTriggersTableName(this.props.triggersTable.title);
    }
    if (
      prevProps?.triggersTable?.description !== this.props?.triggersTable?.description &&
      this.props?.triggersTable?.description !== undefined
    ) {
      this.setState({
        triggersTableDescription: this.props.triggersTable.description,
      });
      dispatchSetTriggersTableDescription(this.props.triggersTable.description);
    }

    if (
      typeof this.state.triggersTableAnalysisAxis === 'string' ||
      prevProps?.triggersTable?.id !== this.props?.triggersTable?.id
    ) {
      const stateAnalysisAxis = getAnalysisAxisOptions(t).find(
        option => option.value === this.props.triggersTable?.settings?.analysisAxis,
      );
      this.setState({
        triggersTableAnalysisAxis: stateAnalysisAxis,
      });
    }
    if (
      (this.state.triggersTableItemsToDisplay === undefined ||
        prevProps?.triggersTable?.id !== this.props?.triggersTable?.id) &&
      prevProps?.triggersTable?.settings?.itemsToDisplay !== this.props?.triggersTable?.settings?.itemsToDisplay
    ) {
      const variableItemsToDisplay = this.props?.triggersTable?.settings?.itemsToDisplay;
      const itemsOptions = getItemsToDisplayOptions(criteriaKeysOptions, t);

      const stateItemsToDisplay = getStateItemsToDisplay(variableItemsToDisplay, itemsOptions);
      this.setState({
        triggersTableItemsToDisplay: stateItemsToDisplay,
      });
    }
    if (
      typeof this.state.triggersTableCalculationCriteria === 'string' ||
      prevProps?.triggersTable?.id !== this.props?.triggersTable?.id
    ) {
      const stateCalculationCriteria = getCalculationCriteriaOptions(t).find(
        option => option.value === this.props?.triggersTable?.settings?.calculationCriteria,
      );
      this.setState({
        triggersTableCalculationCriteria: stateCalculationCriteria,
      });
    }
    if (
      (typeof parseFloat(this.state.triggersTableSignificanceThreshold) !== 'number' ||
        prevProps?.triggersTable?.id !== this.props?.triggersTable?.id) &&
      !isNaN(this.props?.triggersTable?.settings?.significanceThreshold)
    ) {
      const stateSignificanceThreshold = parseFloat(this.props?.triggersTable?.settings?.significanceThreshold);
      this.setState({
        triggersTableSignificanceThreshold: stateSignificanceThreshold,
      });
    }
  }

  onNameChange(e: { target: { value: any } }) {
    const { dispatchSetTriggersTableName } = this.props;
    this.setState({ triggersTableName: e.target.value });
    dispatchSetTriggersTableName(e.target.value);
  }

  onDescriptionChange(e: { target: { value: any } }) {
    const { dispatchSetTriggersTableDescription } = this.props;
    this.setState({ triggersTableDescription: e.target.value });
    dispatchSetTriggersTableDescription(e.target.value);
  }

  onCancel() {
    const { toggle } = this.props;
    toggle();
  }

  getThematicsVariable() {
    const { settings: temporaryTriggersSettings } = this.props.temporaryTriggers;
    const { thematics } = temporaryTriggersSettings;
    return thematics;
  }

  getCriteriaKeysVariable() {
    const { temporaryTriggers } = this.props;

    const { settings: temporaryTriggersSettings } = temporaryTriggers;
    const { criteriaKeys } = temporaryTriggersSettings;

    const criteriaKeysArray: any[] = [];
    criteriaKeys.map(
      (key: { filterCategory?: string; option?: { label: string }; name?: string; values?: [string] }) => {
        if (key?.name && key?.values.length > 0) {
          criteriaKeysArray.push(key);
        }
        let criteriaKeysFoundCategory = criteriaKeysArray.find(criteriaKey => criteriaKey.name === key.filterCategory);

        if (!criteriaKeysFoundCategory && key.filterCategory !== undefined && key?.option?.label !== undefined) {
          criteriaKeysArray.push({ name: key.filterCategory, values: [key?.option?.label] });
        }
        if (criteriaKeysFoundCategory) {
          const existingValues = criteriaKeysFoundCategory.values;
          const newValues = uniq([...existingValues, key?.option?.label]);

          criteriaKeysFoundCategory = {
            ...criteriaKeysFoundCategory,
            values: newValues,
          };
          const indexToReplace = criteriaKeysArray.findIndex(criteriaKey => criteriaKey.name === key.filterCategory);

          if (indexToReplace !== -1) {
            criteriaKeysArray.splice(indexToReplace, 1, criteriaKeysFoundCategory);
          }
        }
      },
    );
    return criteriaKeysArray;
  }

  getRenameModalFooter() {
    const { t, triggersTable, toggle, currentProject, temporaryTriggers } = this.props;
    const {
      triggersTableName,
      triggersTableDescription,
      triggersTableAnalysisAxis,
      triggersTableCalculationCriteria,
      triggersTableSignificanceThreshold: stateTriggersTableSignificanceThreshold,
    } = this.state;
    const { settings: temporaryTriggersSettings, data } = temporaryTriggers;
    const { itemsToDisplay, range } = temporaryTriggersSettings;

    return (
      <Footer>
        <Cancel
          onClick={() => {
            toggle();
          }}
        >
          {t('button:Cancel')}
        </Cancel>
        <MutationSaveTriggersTable
          variables={{
            projectId: currentProject && currentProject.id,
            id: triggersTable.id,
            title: triggersTableName,
            description: triggersTableDescription,
            analysisAxis: triggersTableAnalysisAxis?.value,
            itemsToDisplay,
            calculationCriteria: triggersTableCalculationCriteria?.value,
            significanceThreshold: parseFloat(stateTriggersTableSignificanceThreshold),
            range,
            thematics: this.getThematicsVariable(),
            criteriaKeys: this.getCriteriaKeysVariable(),
            data,
          }}
          callBackAction={this.onSubmit}
        >
          <CreateButton>{t('triggers:Rename')}</CreateButton>
        </MutationSaveTriggersTable>
      </Footer>
    );
  }

  initModalValues = {
    config: {
      date: false,
      mentions: false,
    },
  };

  render() {
    const propsModal = this.initModalValues;
    const { t, triggersTable, isOpen, title, toggle, subtitle } = this.props;
    const { triggersTableName, triggersTableDescription } = this.state;

    const MODAL_CONTENT_CONFIG = {
      cancelButton: {
        text: t('button:Cancel'),
      },
      validateButton: {
        text: t('button:Create this triggers table'),
        disabled: !triggersTableName,
      },
      modalBody: (
        <>
          {!triggersTable && (
            <>
              <Description>{t('Name and describe your new triggers table')}</Description>
              <hr />
            </>
          )}
          <FormGroup>
            <StyledLabel>{t('Name of triggers table')}</StyledLabel> <br />
            <StyledQInput primary maxLength={50} value={triggersTableName} onChange={this.onNameChange} />
          </FormGroup>
          <FormGroup>
            <StyledLabel>{t('Description')}</StyledLabel> <br />
            <StyledTextArea
              as="textarea"
              primary
              rows={2}
              value={triggersTableDescription}
              onChange={this.onDescriptionChange}
              maxLength={175}
            />
          </FormGroup>
        </>
      ),
    };

    return (
      <QModal
        footer={this.getRenameModalFooter()}
        isOpen={isOpen}
        title={t(title)}
        onClose={toggle}
        subtitle={t(subtitle)}
        {...propsModal}
      >
        <StyledQModalBody>{MODAL_CONTENT_CONFIG.modalBody}</StyledQModalBody>
      </QModal>
    );
  }
}

const mapStateToProps = (state: { triggers: { projectTriggersTables: any }; projects: { currentProject: any } }) => ({
  triggersTablesList: state.triggers?.projectTriggersTables,
  currentProject: state.projects.currentProject,
  temporaryTriggers: state.triggers?.temporaryTriggers,
  criteriaKeysOptions: state.filters?.projectFilters?.criteriaKeys?.normal,
});

const mapDispatchToProps = (dispatch: (arg0: { type: string; data: any }) => void) => ({
  dispatchSetTriggersTableName: (newState: any) => {
    dispatch(setTriggersTableName(newState));
  },
  dispatchSetTriggersTableDescription: (newState: any) => {
    dispatch(setTriggersTableDescription(newState));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('triggers', 'button')(TriggersRenameModal));
