import { addOriginalColorsInData } from "_Resources/Triggers/Services/helpers";

const idleState = {
  id: '',
  title: '',
  description: '',
  isDefault: '',
  createdBy: {
    email: '',
    firstName: '',
    lastName: '',
  },
  settings: {
    analysisAxis: {
      value: 'emotion',
      label: 'Emotion',
    },
    itemsToDisplay: {
      value: 'thematic',
      label: 'Thematics',
    },
    calculationCriteria: {
      value: 'percentage',
      label: 'Percentage',
    },
    significanceThreshold: 5,
    range: {
      startDate: '',
      endDate: '',
    },
    thematics: [],
    criteriaKeys: [],
  },
  data: [],
  customizations: [],
};

export const setActiveTriggersTable = (state, { activeTriggersTable }) => {
  if (activeTriggersTable) {
    const { createdBy, settings, id, title, description, isDefault, data, customizations } = activeTriggersTable;

    const dataWithColors = addOriginalColorsInData(data);

    return {
      ...state,
      activeTriggersTable: {
        ...idleState,
        id,
        title,
        description,
        isDefault,
        createdBy,
        settings,
        data: dataWithColors,
        customizations,
      },
    };
  }
  return {
    ...state,
    activeTriggersTable: null,
  };
};
