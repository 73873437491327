import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useQuery, useMutation } from 'react-apollo';
import { loadStripe } from '@stripe/stripe-js';
import QModal from '_Components/Modals/QModal/QModal';
import QSwitcher from '_Components/Buttons/QSwitcher/QSwitcher';
import QBooleanSwitch from '_Components/Fields/QBooleanSwitch/QBooleanSwitch';
import QButton from '_Components/Buttons/QButton/QButton';
import Icon from '_Components/Icons/Icon';
import BillingSection from '_Cards/SettingsCards/AccountDetailsCard/_Components/AddressInfosSection';
import ACCOUNT_DETAILS_QUERY from '_Cards/SettingsCards/AccountDetailsCard/graphql/accountDetails';
import { lightBlue, grayShades } from 'styles/abstracts/colors';
import SAVE_ACCOUNT_DETAILS_MUTATION from '_Cards/SettingsCards/AccountDetailsCard/graphql/saveAccountSettings';
import SubscriptionsPanels from './_Components/SubscriptionsPanels/SubscriptionsPanels';
import OnTheGoPanels from './_Components/OnTheGoPanels/OnTheGoPanels';
import Checkout from './_Components/Checkout/Checkout';
import LIST_PLANS_QUERY from './graphql/listPlans';
import CREATE_CHECKOUT_MUTATION from './graphql/stripeCreateCheckoutSession';

const Modal = styled(QModal)`
  min-width: ${({ itemPriceId }) => !itemPriceId && '75vw'} !important;
  ${({ itemPriceId }) =>
    !itemPriceId &&
    `
      & .modal-body {
        padding: 0 !important;
      }
`}
`;

const TabsContainer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  margin-bottom: 1em;
`;

const PlansContainer = styled.div`
  display: inline-flex;
  justify-content: space-around;
  width: 100%;
  margin: 1em 0;
`;

const SwitcherContainer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const InfoMessage = styled.p`
  font-weight: bolder;
  /* color: ${grayShades.g800}; */
  display: flex;
  align-items: center;
  margin-left: 1em;
  width: 100%;
  justify-content: center;

  & span {
    margin-left: 0.5em;
  }
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const UpgradePlanModal = ({
  isOpen,
  open,
  isUserAdmin,
  accountId,
  accountName,
  addMoreCredits,
  toggleAddMoreCredits,
  t,
  address,
  companyName: compName,
}) => {
  const [isMonthlySelected, selectMonthly] = useState(false);
  const [category, setCategory] = useState('subs');
  const [itemPriceId, setItemPriceId] = useState('');
  const [enterAddress, setEnterAddress] = useState(false);
  const [nameId, setNameId] = useState('');
  const [addressError, setAddressError] = useState(false);
  const [isAddressLoading, setAddressLoading] = useState(false);
  const [nbPack, setNbPack] = useState(1);

  const [companyName, setCompanyName] = useState('');
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [alpha3Cc, setAlpha3Cc] = useState(null);
  const [city, setCity] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [stateProvince, setStateProvince] = useState(null);

  const { data } = useQuery(LIST_PLANS_QUERY);

  const [stripeCreateCheckoutSession] = useMutation(CREATE_CHECKOUT_MUTATION);
  const [saveAccountSettings] = useMutation(SAVE_ACCOUNT_DETAILS_MUTATION, {
    variables: {
      name: accountName,
      companyName,
      address1,
      address2,
      address3,
      alpha3Cc,
      city,
      postalCode,
      stateProvince,
    },
    update: (cache, { data: { saveAccountSettings: saveSettings } }) => {
      const address = {
        address1,
        address2,
        address3,
        alpha3Cc,
        city,
        postalCode,
        stateProvince,
      };
      try {
        const { accountDetails } = cache.readQuery({
          query: ACCOUNT_DETAILS_QUERY,
          variables: { accountId },
        });
        accountDetails.address = address;
        accountDetails.companyName = companyName;
        accountDetails.name = name;
        cache.writeQuery({
          query: ACCOUNT_DETAILS_QUERY,
          variables: { accountId },
          data: { accountDetails: { ...accountDetails } },
        });
      } catch (e) {
        cache.writeQuery({
          query: ACCOUNT_DETAILS_QUERY,
          variables: { accountId },
          data: { accountDetails: { address, companyName, name } },
        });
      }
    },
  });

  const getAddressButtonDisabled = () => {
    return (
      (!address1 || address1?.trim().length === 0) &&
      (!city || city.trim().length === 0) &&
      (!postalCode || postalCode.trim().length === 0) &&
      (!alpha3Cc || alpha3Cc.trim().length === 0)
    );
  };

  const options = [
    { label: 'Subscriptions', id: 'subs' },
    { label: 'Pay as you go', id: 'otg' },
  ];
  const footer = enterAddress ? (
    <FooterContainer>
      <QButton onClick={() => setEnterAddress(false)}>
        {t('button:Return')}
      </QButton>
      <QButton
        disabled={getAddressButtonDisabled()}
        loading={isAddressLoading}
        onClick={() => {
          setAddressLoading(true);
          saveAccountSettings().then(async ({ data: res }) => {
            if (!res.saveAccountSettings?.errors) {
              const stripe = await stripePromise;
              stripeCreateCheckoutSession({
                variables: {
                  itemQuantity: itemPriceId ? nbPack : 1,
                  itemPrice:
                    itemPriceId ||
                    data?.listPlans?.plans?.find(item => item.name === nameId)
                      .itemPrice,
                  paymentType: itemPriceId ? 'PAYMENT' : 'SUBSCRIPTION',
                },
              }).then(({ data }) => {
                return stripe
                  .redirectToCheckout({
                    sessionId: data?.stripeCreateCheckoutSession?.sessionId,
                  })
                  .then(result => {
                    if (result.error) {
                      console.error(
                        'Plan l.93 result.error.message',
                        result.error.message,
                      );
                    }
                  });
              });
            } else {
              setAddressError(true);
            }
          });
        }}
      >
        {t('button:Pay')}
      </QButton>
    </FooterContainer>
  ) : (
    <>
      <QButton
        onClick={() => {
          open(false);
          setItemPriceId(null);
          toggleAddMoreCredits && toggleAddMoreCredits(false);
        }}
      >
        {t('button:Close')}
      </QButton>
    </>
  );

  const [modalFooter, setModalFooter] = useState(footer);

  useEffect(() => {
    if (!itemPriceId) {
      setModalFooter(footer);
    }
  }, [itemPriceId]);

  useEffect(() => {
    setItemPriceId(
      addMoreCredits
        ? data?.listPlans?.plans?.find(item => item.name === 'emotion_start')
            .itemPrice
        : '',
    );
  }, [addMoreCredits]);

  useEffect(() => {
    setCompanyName(compName);
    setAlpha3Cc(address?.alpha3Cc);
  }, [compName, address]);

  useEffect(() => {
    setModalFooter(footer);
  }, [enterAddress]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        open(false);
        toggleAddMoreCredits && toggleAddMoreCredits(false);
      }}
      title={itemPriceId ? 'Checkout' : 'Choose a plan'}
      subtitle={enterAddress && "Don't worry, it's almost over..."}
      footer={modalFooter}
      itemPriceId={itemPriceId}
      category={category}
      config={{ date: false, mentions: false }}
    >
      {!itemPriceId ? (
        <>
          {enterAddress ? (
            <>
              <InfoMessage>
                <Icon icon="INTERFACE_INFO" />{' '}
                <span>
                  {t(
                    'It seems your address is not complete. Please fulfill your address details for the billing.',
                  )}
                </span>
              </InfoMessage>
              <BillingSection
                companyName={companyName}
                setCompanyName={setCompanyName}
                address1={address1}
                address2={address2}
                address3={address3}
                alpha3Cc={alpha3Cc}
                city={city}
                postalCode={postalCode}
                stateProvince={stateProvince}
                setAddress1={setAddress1}
                setAddress2={setAddress2}
                setAddress3={setAddress3}
                setAlpha3Cc={setAlpha3Cc}
                setCity={setCity}
                setPostalCode={setPostalCode}
                setStateProvince={setStateProvince}
                isUserAdmin={isUserAdmin}
              />

              {addressError && (
                <ErrorMessage>
                  {t('card:One or more fields have not been filled in.')}
                </ErrorMessage>
              )}
            </>
          ) : (
            <>
              <TabsContainer>
                <QSwitcher
                  selected={category}
                  options={options}
                  onOptionSelected={setCategory}
                  padding="0.25rem 1rem"
                  display="inline-flex"
                  width="auto"
                />
              </TabsContainer>
              {category === 'subs' && (
                <SwitcherContainer>
                  <QBooleanSwitch
                    value={isMonthlySelected}
                    onChanged={() => selectMonthly(!isMonthlySelected)}
                    staticColor
                    label={t('Yearly (20% off)')}
                  />
                  {t('Monthly')}
                </SwitcherContainer>
              )}

              <PlansContainer category={category}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {category === 'otg' ? (
                  <OnTheGoPanels setItemPriceId={setItemPriceId} t={t} />
                ) : (
                  <SubscriptionsPanels
                    isMonthlySelected={isMonthlySelected}
                    setItemPriceId={setItemPriceId}
                    t={t}
                    setEnterAddress={setEnterAddress}
                    hasAddress={address?.address1}
                    setNameId={setNameId}
                  />
                )}
              </PlansContainer>
            </>
          )}
        </>
      ) : enterAddress ? (
        <>
          <InfoMessage>
            <Icon icon="INTERFACE_INFO" />{' '}
            <span>
              {t(
                'It seems your address is not complete. Please fulfill your address details for the billing.',
              )}
            </span>
          </InfoMessage>
          <BillingSection
            companyName={companyName}
            setCompanyName={setCompanyName}
            address1={address1}
            address2={address2}
            address3={address3}
            alpha3Cc={alpha3Cc}
            city={city}
            postalCode={postalCode}
            stateProvince={stateProvince}
            setAddress1={setAddress1}
            setAddress2={setAddress2}
            setAddress3={setAddress3}
            setAlpha3Cc={setAlpha3Cc}
            setCity={setCity}
            setPostalCode={setPostalCode}
            setStateProvince={setStateProvince}
            isUserAdmin={isUserAdmin}
          />
          {addressError && (
            <ErrorMessage>
              {t('card:One or more fields have not been filled in.')}
            </ErrorMessage>
          )}
        </>
      ) : (
        <Checkout
          itemPriceId={itemPriceId}
          setModalFooter={setModalFooter}
          setItemPriceId={setItemPriceId}
          addMoreCredits={addMoreCredits}
          toggleAddMoreCredits={toggleAddMoreCredits}
          t={t}
          hasAddress={address?.address1}
          setNameId={setNameId}
          setEnterAddress={setEnterAddress}
          nbPack={nbPack}
          setNbPack={setNbPack}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = state => ({
  accountId: get(state, ['auth', 'user', 'account', 'id']),
  accountName: get(state, ['auth', 'user', 'account', 'name']),
  isUserAdmin: get(state, ['auth', 'user', 'isAdmin']),
});

export default connect(mapStateToProps)(UpgradePlanModal);
