import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useMutation } from 'react-apollo';
import { inRange, get } from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import QPanel from '_Components/Panels/QPanel/QPanel';
import QButton from '_Components/Buttons/QButton/QButton';
import { grayShades, red } from 'styles/abstracts/colors';
import BodyContent from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/BodyContent';
import { CARD_STEPS } from 'Routes/Settings/ProjectConfiguration/utils';
import getProjectListCardData from 'Routes/Main/MainPage/_Components/ProjectListCard/getProjectListCardData';
import createProjectMutation from './graphql/createReviewshakeProject';
import ProjectConfigurationHeader from './_Components/ProjectConfigurationHeader/ProjectConfigurationHeader';
import createSaasUploadConfigMutation from './graphql/createSaasUploadConfig';

const StyledQPanel = styled(QPanel)`
  padding: 0 !important;
`;

const Footer = styled.div`
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${grayShades.g300};
`;

const ErrorMessage = styled.p`
  color: ${red};
  width: 100%;
  text-align: center;
  font-weight: bold;
`;

const ProjectConfigurationCard = ({ setActiveStep, t, history, location, projectsList }) => {
  const [currentCardStep, setCurrentCardStep] = useState(0);
  const [error, setError] = useState(false);

  const [industry, setIndustry] = useState(undefined);
  const [lang, setLang] = useState(undefined);
  const [useCase, setUseCase] = useState(undefined);
  const [selectedSources, setSelectedSources] = useState([]);
  const [sources, setSources] = useState([]);
  const [startDate, setStartDate] = useState(undefined);
  const [settings, setSettings] = useState(undefined);
  const [automation, setAutomation] = useState(undefined);
  const [projectName, setProjectName] = useState(undefined);
  const [emailEndProcessing, checkEmailEndProcessing] = useState(false);
  const [automationFrequency, setAutomationFrequency] = useState({
    every: 'week',
    on: 'sunday',
    at: 8,
  });

  const [createSaasUploadConfig] = useMutation(createSaasUploadConfigMutation);

  const [sendProjectSettings] = useMutation(createProjectMutation, {
    update: (cache, { data: { createReviewshakeProject } }) => {
      try {
        const projectList = cache.readQuery({
          query: getProjectListCardData,
        }).results.projects;
        const newProject = {
          id: createReviewshakeProject?.projectId,
          name: createReviewshakeProject?.projectName,
          state: createReviewshakeProject?.state,
          period: {
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment()
              .subtract(1, 'day')
              .format('YYYY-MM-DD'),
          },
          mentionCount: 0,
          eindex: { value: null },
          eindexUnitFahrenheit: false,
          updatedAt: moment().format('L'),
        };
        projectList.push(newProject);
        cache.writeQuery({
          query: getProjectListCardData,
          data: {
            results: {
              projects: projectList,
            },
          },
        });
      } catch (e) {
        const projects = projectsList;
        const newProject = {
          id: createReviewshakeProject?.projectId,
          name: createReviewshakeProject?.projectName,
          state: createReviewshakeProject?.state,
          period: {
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment()
              .subtract(1, 'day')
              .format('YYYY-MM-DD'),
          },
          mentionCount: 0,
          eindex: { value: null },
          eindexUnitFahrenheit: false,
          updatedAt: moment().format('L'),
        };
        projects.push(newProject);
        cache.writeQuery({
          query: getProjectListCardData,
          data: {
            results: {
              projects,
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    if (location?.state?.step === 'sources-list') {
      setCurrentCardStep(2);
    }
    if (inRange(currentCardStep, 2)) {
      setActiveStep(1);
    } else if (inRange(currentCardStep, 2, 4)) {
      setActiveStep(2);
    } else if (inRange(currentCardStep, 4, 6)) {
      setActiveStep(3);
    } else if (inRange(currentCardStep, 6, 9)) {
      setActiveStep(4);
    } else if (currentCardStep === 9) {
      setActiveStep(5);
    }
  }, [currentCardStep]);

  const settingsToSend = {
    industryId: industry,
    useCaseId: useCase,
    sources,
    extraction: {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment()
        .subtract(1, 'day')
        .format('YYYY-MM-DD'),
    },
    configurationData: settings,
    automation,
    name: projectName,
    sendEmailAfterProcessing: emailEndProcessing,
    lang,
  };

  const bodyProps = {
    industry,
    setIndustry,
    useCase,
    setUseCase,
    selectedSources,
    setSelectedSources,
    sources,
    setSources,
    startDate,
    setStartDate,
    extraction: settingsToSend.extraction,
    settings,
    setSettings,
    lang,
    setLang,
    automation,
    setAutomation,
    automationFrequency,
    setAutomationFrequency,
    projectName,
    setProjectName,
    emailEndProcessing,
    checkEmailEndProcessing,
  };

  const getNextButtonText = () => {
    if (currentCardStep === 7) {
      return 'The configuration is correct';
    }
    if (currentCardStep === 8) {
      return "Let's find emotions!";
    }
    return 'Next';
  };

  const disableNextButton = () => {
    switch (CARD_STEPS[Object.keys(CARD_STEPS)[currentCardStep]]) {
      case CARD_STEPS.INDUSTRIES:
        return typeof industry !== 'number' && !industry;
      case CARD_STEPS.USE_CASES:
        return typeof useCase !== 'number' && !useCase;
      case CARD_STEPS.SOURCES_LIST:
        return selectedSources.length === 0;
      case CARD_STEPS.SOURCES_CONFIGURATION:
        return !sources || sources.length === 0;
      case CARD_STEPS.PROJECT_NAMING:
        return !projectName || projectName.length === 0;
      default:
        return false;
    }
  };

  const onButtonClick = () => {
    currentCardStep < 8 && setCurrentCardStep(currentCardStep + 1);
    if (currentCardStep === 8) {
      sendProjectSettings({
        variables: { projectSettings: JSON.stringify(settingsToSend) },
      })
        .then(({ data }) => {
          if (data.createReviewshakeProject.errors === null) {
            setCurrentCardStep(9);
            // toggleProcessingToast(true);
          }
        })
        .catch(err => setError(true));
    }
    if (currentCardStep === 2 && selectedSources[0].name === t('File upload')) {
      createSaasUploadConfig({
        variables: { useCaseId: useCase, industryId: industry },
      }).then(({ data }) => {
        const {
          createSaasUploadConfig: { uuid },
        } = data;
        history.push(`/upload-data/${uuid}`);
      });
    }
  };

  return (
    <StyledQPanel>
      {currentCardStep !== 9 && currentCardStep !== 4 && (
        <ProjectConfigurationHeader activeStep={currentCardStep} t={t} />
      )}
      <BodyContent bodyProps={bodyProps} currentCardStep={currentCardStep} />
      {error && <ErrorMessage>{t('card:An error occured')}</ErrorMessage>}
      {currentCardStep < 9 && (
        <Footer>
          <QButton
            hasOpacity
            size="medium"
            onClick={() => {
              setCurrentCardStep(currentCardStep - 1);
            }}
            disabled={currentCardStep === 0}
          >
            {t('button:Back')}
          </QButton>
          <QButton size="medium" onClick={() => onButtonClick()} disabled={disableNextButton()}>
            {t(`button:${getNextButtonText()}`)}
          </QButton>
        </Footer>
      )}
    </StyledQPanel>
  );
};

const mapStateToProps = state => ({
  projectList: get(state, ['projects', 'projectsList']),
});

export default connect(mapStateToProps)(
  withTranslation('projectConfiguration', ['card', 'button'])(ProjectConfigurationCard),
);
