import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { grayShades } from 'styles/abstracts/colors';

const TriggersInformationContainer = styled.div`
  border-radius: 10px;
  background-color: ${grayShades.g200};
  color: ${grayShades.g800};
  font-size: 1rem;
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: row;
  height: 6rem;
  max-height: 6rem;
  min-width: 20rem;
  overflow: auto;
  padding: 10px 20px;
`;

const LabelsContainer = styled.div`
  font-weight: normal;
  height: 100%;
  line-height: 1.5rem;
`;

const InfoContainer = styled.div`
  margin-left: 3rem;
  font-weight: bold;
  height: 100%;
  line-height: 1.5rem;
`;

type TriggersInformationPanelType = {
  email: string;
  firstName: string;
  lastName: string;
  t: Function;
};

const TriggersInformationPanel = ({ email, firstName, lastName, t }: TriggersInformationPanelType) => {
  return (
    <TriggersInformationContainer>
      <LabelsContainer>
        <p>{t('Created by')}{' :'}</p>
        {/* <p>{t('Created on')}{' :'}</p> */}
      </LabelsContainer>
      <InfoContainer>
        <p>{firstName + ' ' + lastName}</p>
        {/* <p>{'00/00/0000'}</p> */}
      </InfoContainer>
    </TriggersInformationContainer>
  );
};

export default withTranslation('card')(TriggersInformationPanel);
