import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '_Components/Icons/Icon';
import _, { get } from 'lodash';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { grayShades, lightBlue } from 'styles/abstracts/colors';
import onClickOutside from 'react-onclickoutside';
import CardTitle from '_Components/QcardPanel/QcardHeader/QCardTitles/CardTitle';
import CardSubTitle from '_Components/QcardPanel/QcardHeader/QCardTitles/CardSubTitle';
import QCardHint from '_Components/QcardPanel/QcardHeader/QcardHints/QCardHints';
import QcardHeaderContext from '_Components/QcardPanel/QcardHeader/QcardHeaderContext';
import ExportCardModale from '_Components/QcardPanel/ExportCard/ExportCardModale';
import ClusterEditableTitle from '_Cards/LandingPageCard/IrritantsTab/ClusterEditableTitle';
import { connect } from 'react-redux';
import BenchmarkDonutGraphSettingsModal from 'Routes/Benchmark/BenchmarkBoard/_Components/BenchmarkGroupCard/BenchmarkDonutGraphSettingsModal';
import ExportTriggersDataModal from 'Routes/Insights/Triggers/_Components/TriggersSubHeader/_Containers/ListTriggers/_Containers/TriggersExportModal/ExportTriggersDataModal';
import exportEmotionalTriggersTableQuery from '_Resources/Triggers/Services/graphql/exportEmotionalTriggersTable';
import TriggersInformationPanel from 'Routes/Insights/Triggers/TriggersInformationPanel';
import TriggersThematicsSelectionSwitch from 'Routes/Insights/Triggers/TriggersThematicsSelectionSwitch';
import { ImageSize } from '../../../styles/abstracts/variables';
import BenchmarkSettingsModal from '../../../../src/Routes/Benchmark/BenchmarkBoard/_Components/BenchmarkGroupCard/BenchmarkSettingsModal';
import ExportDataModal from '../../../_Cards/SettingsCards/ProjectDetailsCard/_Components/ProcessingHistory/_Components/ProcessingHistoryHeader/_Components/ExportDataModal/ExportDataModal';
import EXPORT_PROJECT_DATA_QUERY from '../../../_Cards/SettingsCards/ProjectDetailsCard/graphql/exportProjectData';
import QToast from '../../QToast/QToast';
import BenchmarkHorizontalGraphSettingsModal from '../../../Routes/Benchmark/BenchmarkBoard/_Components/BenchmarkGroupCard/BenchmarkHorizontalGraphSettingsModal';

const StyledButtonDropdown = styled(ButtonDropdown)`
  button {
    padding: 0;

    &:hover {
      background-color: ${grayShades.g200};
    }

    &:active {
      color: inherit;
      outline: none;
      background-color: ${grayShades.g200};
    }
  }
`;

const StyledQcardHeader = styled.div`
  padding: 1rem 1.5rem;
  position: relative;
`;

const StyledDropdownToggle = styled(DropdownToggle)`
  background: none !important;

  &:focus {
    box-shadow: none !important;
  }
`;
const StyledDropdownMenu = styled(DropdownMenu)`
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px !important;
  padding: 12px 0 !important;
  border: none !important;
  min-width: 12rem !important;
  top: ${props => (props.landingSection ? '3em !important' : '0')};
`;
const StyledDropdownItem = styled(DropdownItem)`
  font-size: 1rem !important;
  font-weight: bold !important;
  padding: 1rem !important;

  svg {
    margin-right: 1rem;
  }
`;
const StyledQcardHeaderAdditionnalIcons = styled.span`
  position: absolute;
  top: ${props => (props.landingSection ? '-0.2rem' : '0.5rem')};
  right: ${props => (props.landingSection ? '-1.5rem' : props.triggersSection ? '0.5rem' : '0rem')};
  cursor: pointer;
  ${props => (props.triggersSection ? 'display: flex; flex-direction: row;' : null)};
`;

const HeaderContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

class QcardHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      modal: false,
      settingsModal: false,
      horizontalGraphSettingsModal: false,
      donutGraphSettingsModal: false,
      toastMessage: {},
      isModalOpen: false,
      exportTriggersDataModal: false,
      exportTriggersDataToastMessage: {},
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleSettingsModal = this.toggleSettingsModal.bind(this);
    this.onSettingsModalClose = this.onSettingsModalClose.bind(this);
    this.toggleDonutGraphSettingsModal = this.toggleDonutGraphSettingsModal.bind(this);
    this.onDonutGraphSettingsModalClose = this.onDonutGraphSettingsModalClose.bind(this);
    this.toggleHorizontalGraphSettingsModal = this.toggleHorizontalGraphSettingsModal.bind(this);
    this.onHorizontalGraphSettingsModalClose = this.onHorizontalGraphSettingsModalClose.bind(this);
  }

  myClickOutsideHandler = () => {
    this.setState({ isOpen: false });
  };

  toggleModal() {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  }

  toggleSettingsModal() {
    const { settingsModal } = this.state;
    this.setState({
      settingsModal: !settingsModal,
    });
  }

  onSettingsModalClose() {
    this.setState({
      settingsModal: false,
    });
  }

  toggleHorizontalGraphSettingsModal() {
    const { horizontalGraphSettingsModal } = this.state;
    this.setState({
      horizontalGraphSettingsModal: !horizontalGraphSettingsModal,
    });
  }

  onHorizontalGraphSettingsModalClose() {
    this.setState({
      horizontalGraphSettingsModal: false,
    });
  }

  toggleDonutGraphSettingsModal() {
    const { donutGraphSettingsModal } = this.state;
    this.setState({
      donutGraphSettingsModal: !donutGraphSettingsModal,
    });
  }

  onDonutGraphSettingsModalClose() {
    this.setState({
      donutGraphSettingsModal: false,
    });
  }

  PredefinedDropdownOptions = () => {
    const { history, exportClustersData } = this.props;
    const { isModalOpen, exportTriggersDataModal } = this.state;
    return {
      EXPORT: {
        text: 'Export',
        onClick: () => this.toggleModal(),
        icon: {
          iconType: 'DOWNLOAD',
          iconColor: null,
        },
        topSeparator: false,
      },
      PROJECT_SETTINGS: {
        text: 'Project settings',
        onClick: () => {
          window.localStorage.setItem('openedSettings', 'projects');
          history.push('/settings');
        },
        icon: {
          iconType: 'INTERFACE_GEAR',
          iconColor: null,
        },
        topSeparator: true,
      },
      CLUSTERS_DATA: {
        text: 'Export clusters',
        onClick: () => {
          exportClustersData();
        },
        icon: {
          iconType: 'DOWNLOAD',
          iconColor: null,
        },
        topSeparator: false,
      },
      EMOTION_CLUSTERS_DATA: {
        text: 'Export clusters',
        onClick: () => this.setState({ isModalOpen: !isModalOpen }),
        icon: {
          iconType: 'DOWNLOAD',
          iconColor: null,
        },
        topSeparator: false,
      },
      BENCHMARK_GRAPH_SETTINGS: {
        text: 'Settings',
        onClick: () => this.toggleSettingsModal(),
        icon: {
          iconType: 'INTERFACE_GEAR',
          iconColor: null,
        },
        topSeparator: false,
      },
      BENCHMARK_HORIZONTAL_GRAPH_SETTINGS: {
        text: 'Settings',
        onClick: () => {
          this.toggleHorizontalGraphSettingsModal();
        },
        icon: {
          iconType: 'INTERFACE_GEAR',
          iconColor: null,
        },
        topSeparator: false,
      },
      BENCHMARK_DONUT_GRAPH_SETTINGS: {
        text: 'Settings',
        onClick: () => {
          this.toggleDonutGraphSettingsModal();
        },
        icon: {
          iconType: 'INTERFACE_GEAR',
          iconColor: null,
        },
        topSeparator: false,
      },
      EXPORT_TRIGGERS_DATA: {
        text: 'Export data',
        onClick: () => {
          this.setState({ exportTriggersDataModal: !exportTriggersDataModal });
        },
        icon: {
          iconType: 'DOWNLOAD',
          iconColor: null,
        },
        topSeparator: false,
      },
    };
  };

  dropdownConfig = () => ({
    EXPORT: 'EXPORT',
    PROJECT_SETTINGS: 'PROJECT_SETTINGS',
    CLUSTERS_DATA: 'CLUSTERS_DATA',
    BENCHMARK_GRAPH_SETTINGS: 'BENCHMARK_GRAPH_SETTINGS',
    BENCHMARK_DONUT_GRAPH_SETTINGS: 'BENCHMARK_DONUT_GRAPH_SETTINGS',
    BENCHMARK_HORIZONTAL_GRAPH_SETTINGS: 'BENCHMARK_HORIZONTAL_GRAPH_SETTINGS',
    EMOTION_CLUSTERS_DATA: 'EMOTION_CLUSTERS_DATA',
    EXPORT_TRIGGERS_DATA: 'EXPORT_TRIGGERS_DATA',
  });

  getAllDropdownOptions() {
    const { dropDownOptions, specificDropDownOptions } = this.props;

    const relevantOptions = _.filter(_.cloneDeep(this.dropdownConfig()), config =>
      !_.isNull(dropDownOptions) ? _.cloneDeep(dropDownOptions).includes(config) : null,
    );
    const optionsSelected =
      specificDropDownOptions !== null
        ? [...specificDropDownOptions, ...relevantOptions.map(option => this.PredefinedDropdownOptions()[option])]
        : relevantOptions.map(option => this.PredefinedDropdownOptions()[option]);
    this.setState({
      allDropdownOptions: optionsSelected,
    });
  }

  componentDidMount() {
    this.getAllDropdownOptions();
  }

  render() {
    const {
      title,
      titleSecondPart,
      numberOfMentions,
      displayCardTitle,
      displayCardSubTitle,
      displayMentionsSubtitle,
      displayDateSubtitle,
      subtitleAdditionalContent,
      hints,
      displayHints,
      mentionCount,
      referenceMentions,
      buttonRight,
      dataByFiltersCard,
      hideFilterDates,
      landingSection,
      additionalIcon,
      additionalAction,
      clusterEditableTitle,
      editTitleMutation,
      editTitleMutationVariables,
      updateCallback,
      t,
      filters,
      projectId,
      projectName,
      selectedClusters,
      isCardOpened,
      referencePeriod,
      closeCard,
      activeTriggersTableId,
      activeTriggersNumberOfMentions,
      activeTriggersOnlySelectedThematics,
      triggersCreatedBy,
      triggersSection,
    } = this.props;

    const {
      modal,
      horizontalGraphSettingsModal,
      donutGraphSettingsModal,
      toastMessage,
      isModalOpen,
      settingsModal,
      isOpen,
      allDropdownOptions,
      exportTriggersDataModal,
      exportTriggersDataToastMessage,
    } = this.state;

    const { email, firstName, lastName } = triggersCreatedBy || {};

    return (
      <StyledQcardHeader>
        {(title || titleSecondPart) &&
          displayCardTitle &&
          (buttonRight ? (
            <HeaderContainer>
              {!clusterEditableTitle && (
                <div style={{ width: '100%' }}>
                  <CardTitle title={title} titleSecondPart={titleSecondPart} />
                </div>
              )}
              {clusterEditableTitle && (
                <div style={{ width: '100%' }}>
                  <ClusterEditableTitle
                    title={title}
                    titleSecondPart={titleSecondPart}
                    editTitleMutation={editTitleMutation}
                    editTitleMutationVariables={editTitleMutationVariables}
                    updateCallback={updateCallback}
                  />
                </div>
              )}
              {buttonRight}
            </HeaderContainer>
          ) : (
            <>
              {!clusterEditableTitle && <CardTitle title={title} titleSecondPart={titleSecondPart} />}
              {clusterEditableTitle && (
                <ClusterEditableTitle
                  title={title}
                  titleSecondPart={titleSecondPart}
                  editTitleMutation={editTitleMutation}
                  editTitleMutationVariables={editTitleMutationVariables}
                  updateCallback={updateCallback}
                />
              )}
            </>
          ))}
        {displayCardSubTitle && (
          <CardSubTitle
            title={title}
            displayMentionsSubtitle={displayMentionsSubtitle}
            displayDateSubtitle={displayDateSubtitle}
            subtitleAdditionalContent={subtitleAdditionalContent}
            numberOfMentionsForced={triggersSection ? activeTriggersNumberOfMentions : mentionCount || numberOfMentions}
            referencePeriod={referencePeriod}
            referenceMentions={referenceMentions}
            hideFilterDates={hideFilterDates}
            triggersSection={triggersSection}
          />
        )}
        {(hints || !_.isEmpty(allDropdownOptions || isCardOpened)) && (
          <StyledQcardHeaderAdditionnalIcons
            id={`${title}-iconHeaderCard`}
            landingSection={landingSection}
            triggersSection={triggersSection}
          >
            {additionalIcon && additionalAction && (
              <span className="mr-2" onClick={additionalAction} role="navigation" aria-hidden="true">
                <Icon icon={additionalIcon} size={20} color={grayShades.g400} />
              </span>
            )}
            {triggersSection && <TriggersThematicsSelectionSwitch />}
            {hints.length > 0 && displayHints && <QCardHint hints={hints} landingSection={landingSection} />}
            {!_.isEmpty(allDropdownOptions) && (
              <StyledButtonDropdown isOpen={isOpen} toggle={() => this.setState({ isOpen: !isOpen })} direction="right">
                <StyledDropdownToggle>
                  <span className="mr-1">
                    <Icon icon="INTERFACE_DOTS_VERTICAL" size={20} color={grayShades.g400} />
                  </span>
                </StyledDropdownToggle>
                <StyledDropdownMenu className="styledDropdownMenu" landingSection={landingSection}>
                  {allDropdownOptions !== 'undefined' &&
                    allDropdownOptions.map(option => {
                      return option !== null && typeof option !== 'undefined' ? (
                        <>
                          {option.topSeparator && <hr />}
                          <StyledDropdownItem
                            onClick={() => {
                              option.onClick();
                              this.setState({ isOpen: false });
                            }}
                          >
                            {option.icon.iconType && (
                              <Icon
                                icon={option.icon.iconType}
                                size={24}
                                color={option.icon.iconColor || grayShades.g800}
                              />
                            )}
                            {t(option.text)}
                          </StyledDropdownItem>
                        </>
                      ) : null;
                    })}
                </StyledDropdownMenu>
              </StyledButtonDropdown>
            )}
            {isCardOpened && (
              <span role="none" onClick={closeCard()}>
                <Icon icon="INTERFACE_CROSS" size={ImageSize.big} color={grayShades.g400} />
              </span>
            )}
          </StyledQcardHeaderAdditionnalIcons>
        )}
        {(email !== '' || firstName !== '' || lastName !== '') && (
          <TriggersInformationPanel email={email} firstName={firstName} lastName={lastName} />
        )}
        <ExportCardModale
          toggleModal={this.toggleModal}
          isOpen={modal}
          title={title}
          dataByFiltersCard={dataByFiltersCard}
        />

        <ExportDataModal
          t={t}
          setMessage={message => this.setState({ toastMessage: message })}
          isOpen={isModalOpen}
          open={() => this.setState({ isModalOpen: !isModalOpen })}
          projectId={projectId}
          projectName={projectName}
          query={EXPORT_PROJECT_DATA_QUERY}
          additionalVariables={{ ...filters, selectedClusters }}
        />
        {toastMessage.message && <QToast color={lightBlue}>{toastMessage.message}</QToast>}
        <ExportTriggersDataModal
          t={t}
          setMessage={message => this.setState({ exportTriggersDataToastMessage: message })}
          isOpen={exportTriggersDataModal}
          open={() => this.setState({ exportTriggersDataModal: !exportTriggersDataModal })}
          id={activeTriggersTableId}
          query={exportEmotionalTriggersTableQuery}
          activeTriggersOnlySelectedThematics={activeTriggersOnlySelectedThematics}
        />
        {exportTriggersDataToastMessage.message && (
          <QToast color={lightBlue}>{exportTriggersDataToastMessage.message}</QToast>
        )}

        {settingsModal && <BenchmarkSettingsModal onClose={this.onSettingsModalClose} isOpen={settingsModal} />}
        {horizontalGraphSettingsModal && (
          <BenchmarkHorizontalGraphSettingsModal
            onClose={this.onHorizontalGraphSettingsModalClose}
            isOpen={horizontalGraphSettingsModal}
          />
        )}
        {donutGraphSettingsModal && (
          <BenchmarkDonutGraphSettingsModal
            onClose={this.onDonutGraphSettingsModalClose}
            isOpen={donutGraphSettingsModal}
          />
        )}
      </StyledQcardHeader>
    );
  }
}

const clickOutsideConfig = {
  handleClickOutside(instance) {
    return instance.myClickOutsideHandler;
  },
};

QcardHeader.propTypes = {
  projectId: PropTypes.number,
  projectName: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.oneOfType(PropTypes.shape())),
  selectedClusters: PropTypes.arrayOf(PropTypes.string),
  displayCardTitle: PropTypes.bool,
  displayCardSubTitle: PropTypes.bool,
  title: PropTypes.string,
  titleSecondPart: PropTypes.string,
  numberOfMentions: PropTypes.string,
  hints: PropTypes.arrayOf(PropTypes.string),
  displayMentionsSubtitle: PropTypes.bool,
  displayDateSubtitle: PropTypes.bool,
  subtitleAdditionalContent: PropTypes.string,
  displayHints: PropTypes.bool,
  dropDownOptions: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType(PropTypes.string, PropTypes.func, PropTypes.objectOf(PropTypes.string))),
  ),
  specificDropDownOptions: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType(PropTypes.string, PropTypes.func, PropTypes.objectOf(PropTypes.string))),
  ),
  t: PropTypes.func,
  isCardOpened: PropTypes.bool,
  referencePeriod: PropTypes.objectOf(PropTypes.oneOfType(PropTypes.instanceOf(Date))),
  closeCard: PropTypes.func,
  updateCallback: PropTypes.func,
  mentionCount: PropTypes.number,
  referenceMentions: PropTypes.number,
  buttonRight: PropTypes.instanceOf(Element),
  dataByFiltersCard: PropTypes.bool,
  hideFilterDates: PropTypes.bool,
  landingSection: PropTypes.bool,
  additionalIcon: PropTypes.string,
  additionalAction: PropTypes.func,
  clusterEditableTitle: PropTypes.bool,
  editTitleMutation: PropTypes.string,
  editTitleMutationVariables: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.arrayOf(PropTypes.string),
  exportClustersData: PropTypes.func,
  triggersSection: PropTypes.bool,
  activeTriggersTableId: PropTypes.number,
  activeTriggersNumberOfMentions: PropTypes.number,
  activeTriggersOnlySelectedThematics: PropTypes.bool,
  triggersCreatedBy: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
};

QcardHeader.defaultProps = {
  projectId: null,
  projectName: null,
  filters: null,
  selectedClusters: null,
  displayCardTitle: true,
  displayCardSubTitle: true,
  titleSecondPart: null,
  title: null,
  numberOfMentions: null,
  hints: null,
  displayMentionsSubtitle: true,
  displayDateSubtitle: true,
  subtitleAdditionalContent: null,
  displayHints: true,
  dropDownOptions: null,
  specificDropDownOptions: null,
  t: null,
  isCardOpened: false,
  referencePeriod: null,
  closeCard: null,
  updateCallback: null,
  mentionCount: null,
  referenceMentions: null,
  buttonRight: null,
  dataByFiltersCard: false,
  hideFilterDates: false,
  landingSection: false,
  additionalIcon: null,
  additionalAction: null,
  clusterEditableTitle: false,
  editTitleMutation: null,
  editTitleMutationVariables: null,
  history: null,
  exportClustersData: null,
  triggersSection: false,
  activeTriggersTableId: null,
  activeTriggersNumberOfMentions: null,
  activeTriggersOnlySelectedThematics: false,
  triggersCreatedBy: {
    email: '',
    firstName: '',
    lastName: '',
  },
};

function mapStateToProps(state) {
  return {
    mentionBreakdownByCategoryModalOpen: get(state, ['benchmark', 'mentionBreakdownByCategoryModalOpen']),
    projectId: get(state, ['filters', 'filterKeys', 'projectId']),
    filters: get(state, ['filters', 'filterKeys']),
    selectedClusters: get(state, ['filters', 'selectedClusters']),
    projectName: get(state, 'projects.currentProject.name'),
    benchmarkDonutAnalysisAxisToDisplay: get(state, ['benchmark', 'donutAnalysisAxisToDisplay']),
    activeTriggersTableId: get(state, ['triggers', 'activeTriggersTable', 'id']),
    activeTriggersNumberOfMentions: get(state, ['triggers', 'activeTriggersNumberOfMentions']),
    activeTriggersOnlySelectedThematics: get(state, ['triggers', 'activeTriggersOnlySelectedThematics']),
  };
}

export default connect(mapStateToProps)(
  withTranslation('button')(withRouter(QcardHeaderContext(onClickOutside(QcardHeader, clickOutsideConfig)))),
);
