import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DropdownItem } from 'reactstrap';
import { Mutation } from 'react-apollo';
import Icon from '_Components/Icons/Icon';
import queryGetEmotionalTriggersTableList from './graphql/getEmotionalTriggersTableList';
import mutationDuplicateEmotionalTriggersTable from './graphql/duplicateEmotionalTriggersTable';
import { grayShades, opacity, black } from '../../../styles/abstracts/colors';
import { ImageSize, spacing } from '../../../styles/abstracts/variables';
import { fontSizes } from '../../../styles/abstracts/typography';

const OptionsItem = styled(DropdownItem)`
  font-weight: bold !important;
  color: ${props => (props.disabled ? opacity.purple : black)} !important;
  padding: ${spacing.medium} !important;
  cursor: ${props => props.disabled && 'default'} !important;
  font-size: ${fontSizes.smaller};
`;

class MutationDuplicateTriggersTable extends React.Component {
  render() {
    const { t, triggersTableId, canCreateTriggersTable, currentProject } = this.props;
    const { id: projectId } = currentProject;
    return (
      <Mutation
        mutation={mutationDuplicateEmotionalTriggersTable}
        update={(
          cache,
          {
            data: {
              duplicateEmotionalTriggersTable: { emotionalTriggersTable },
            },
          },
        ) => {
          const triggersTableList = cache.readQuery({
            query: queryGetEmotionalTriggersTableList,
            variables: { projectId },
          }).getEmotionalTriggersTableList.emotionalTriggersTables;

          cache.writeQuery({
            query: queryGetEmotionalTriggersTableList,
            variables: { projectId },
            data: {
              getEmotionalTriggersTableList: {
                emotionalTriggersTables: triggersTableList.concat({
                  ...emotionalTriggersTable,
                }),
              },
            },
          });
        }}
      >
        {emotionalTriggersTable => (
          <OptionsItem
            id="duplicateTriggersTableItem"
            disabled={!canCreateTriggersTable}
            onClick={() =>
              emotionalTriggersTable({
                variables: { id: triggersTableId },
              })
            }
          >
            <Icon
              icon="DUPLICATE"
              color={!canCreateTriggersTable ? grayShades.g300 : grayShades.g800}
              size={ImageSize.medium}
            />
            &nbsp; {t('Duplicate')}
          </OptionsItem>
        )}
      </Mutation>
    );
  }
}
MutationDuplicateTriggersTable.propTypes = {
  t: PropTypes.func.isRequired,
  currentProject: PropTypes.number.isRequired,
  triggersTableId: PropTypes.number.isRequired,
  canCreateTriggersTable: PropTypes.bool,
};

MutationDuplicateTriggersTable.defaultProps = {
  canCreateTriggersTable: true,
};

const mapStateToProps = state => ({
  currentProject: state.projects.currentProject,
  canCreateTriggersTable: state.auth.user.account.canCreateTriggersTable || true,
});

export default connect(mapStateToProps)(withTranslation('triggers')(MutationDuplicateTriggersTable));
