import gql from 'graphql-tag';

const queryGetEmotionalTriggersTable = gql`
  query getEmotionalTriggersTable($id: Int!, $applyThematicSelection: Boolean) {
    getEmotionalTriggersTable(id: $id, applyThematicSelection: $applyThematicSelection) {
      id
      title
      description
      isDefault
      createdBy {
        email
        firstName
        lastName
      }
      settings {
        analysisAxis
        itemsToDisplay {
          name
          key
        }
        calculationCriteria
        significanceThreshold
        range {
          startDate
          endDate
        }
        thematics {
          thematic
          subThematic
          subSubThematic
        }
        criteriaKeys {
          name
          values
        }
      }
      customizations {
        originalName
        name
        originalColor
        color
        items {
          originalName
          name
        }
        deleted
        masked
      }
      data {
        originalName
        name
        originalColor
        color
        items {
          originalName
          name
          percentage
          value
        }
        value
        deleted
        masked
      }
    }
  }
`;

export default queryGetEmotionalTriggersTable;
