import { SET_ACTIVE_TRIGGERS_TABLE } from '../Actions/setActiveTriggersTable';
import { SET_TEMPORARY_TRIGGERS_TABLE } from '../Actions/setTemporaryTriggersTable';
import { setActiveTriggersTable } from './helpers/activeTriggersTable';
import { setTemporaryTriggersTable } from './helpers/temporaryTriggersTable';
import { addTriggersFilters, removeTriggersFilters } from './helpers/criteriaKeysFilters';
import { SET_TRIGGERS_RANGE_FILTER } from '../Actions/Filters/setTriggersRangeFilter';
import { ADD_TRIGGERS_FILTERS } from '../Actions/Filters/addTriggersFilters';
import { SET_TRIGGERS_TABLE_NAME } from '../Actions/Filters/setTriggersTableName';
import { SET_TRIGGERS_TABLE_ANALYSIS_AXIS } from '../Actions/Filters/setTriggersTableAnalysisAxis';
import { SET_TRIGGERS_TABLE_ITEMS } from '../Actions/Filters/setTriggersTableItems';
import { SET_TRIGGERS_TABLE_CALCULATION_CRITERIA } from '../Actions/Filters/setTriggersTableCalculationCriteria';
import { SET_TRIGGERS_TABLE_THRESHOLD } from '../Actions/Filters/setTriggersTableThreshold';
import { SET_TRIGGERS_TABLE_DESCRIPTION } from '../Actions/Filters/setTriggersTableDescription';
import { REMOVE_TRIGGERS_FILTERS } from '../Actions/Filters/removeTriggersFilters';
import { SET_PROJECT_TRIGGERS_TABLES } from '../Actions/setProjectTriggersTables';
import { SET_TRIGGERS_TABLE_THEMATICS } from '../Actions/Filters/setTriggersTableThematics';
import { SET_TRIGGERS_TABLE_CRITERIA_KEYS } from '../Actions/Filters/setTriggersTableCriteriaKeys';
import { SET_ACTIVE_TRIGGERS_NUMBER_OF_MENTIONS } from '../Actions/Filters/setActiveTriggersNumberOfMentions';
import { SET_ACTIVE_TRIGGERS_ONLY_SELECTED_THEMATICS } from '../Actions/setActiveTriggersOnlySelectedThematics';

const initialState = {
  activeTriggersTable: {
    id: '',
    title: '',
    description: '',
    isDefault: false,
    settings: {
      analysisAxis: {
        value: 'emotion',
        label: 'Emotion',
      },
      itemsToDisplay: {
        name: 'thematic',
        key: null,
      },
      calculationCriteria: {
        value: 'percentage',
        label: 'Percentage',
      },
      significanceThreshold: 5,
      range: {
        startDate: '',
        endDate: '',
      },
      thematics: [],
      criteriaKeys: [],
    },
  },
  activeTriggersNumberOfMentions: 0,
  activeTriggersOnlySelectedThematics: false,
  projectTriggersTables: [],
  temporaryTriggers: {
    id: '',
    title: '',
    description: '',
    settings: {
      analysisAxis: {
        value: 'emotion',
        label: 'Emotion',
      },
      itemsToDisplay: {
        name: 'thematic',
        key: null,
      },
      calculationCriteria: {
        value: 'percentage',
        label: 'Percentage',
      },
      significanceThreshold: 5,
      range: {
        startDate: '',
        endDate: '',
      },
      thematics: [],
      criteriaKeys: [],
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TRIGGERS_TABLE: {
      return setActiveTriggersTable(state, action);
    }
    case SET_TEMPORARY_TRIGGERS_TABLE: {
      return setTemporaryTriggersTable(state, action);
    }
    case SET_PROJECT_TRIGGERS_TABLES: {
      return {
        ...state,
        projectTriggersTables: action.data,
      };
    }
    case SET_TRIGGERS_RANGE_FILTER: {
      return {
        ...state,
        temporaryTriggers: {
          ...state.temporaryTriggers,
          settings: {
            ...state.temporaryTriggers.settings,
            range: action.data,
          },
        },
      };
    }
    case SET_TRIGGERS_TABLE_NAME: {
      return {
        ...state,
        temporaryTriggers: {
          ...state.temporaryTriggers,
          title: action.data,
        },
      };
    }
    case SET_TRIGGERS_TABLE_DESCRIPTION: {
      return {
        ...state,
        temporaryTriggers: {
          ...state.temporaryTriggers,
          description: action.data,
        },
      };
    }
    case SET_TRIGGERS_TABLE_ANALYSIS_AXIS: {
      return {
        ...state,
        temporaryTriggers: {
          ...state.temporaryTriggers,
          settings: {
            ...state.temporaryTriggers.settings,
            analysisAxis: action.data,
          },
        },
      };
    }
    case SET_TRIGGERS_TABLE_ITEMS: {
      return {
        ...state,
        temporaryTriggers: {
          ...state.temporaryTriggers,
          settings: {
            ...state.temporaryTriggers.settings,
            itemsToDisplay: action.data,
          },
        },
      };
    }
    case SET_TRIGGERS_TABLE_CALCULATION_CRITERIA: {
      return {
        ...state,
        temporaryTriggers: {
          ...state.temporaryTriggers,
          settings: {
            ...state.temporaryTriggers.settings,
            calculationCriteria: action.data,
          },
        },
      };
    }
    case SET_TRIGGERS_TABLE_THRESHOLD: {
      return {
        ...state,
        temporaryTriggers: {
          ...state.temporaryTriggers,
          settings: {
            ...state.temporaryTriggers.settings,
            significanceThreshold: action.data,
          },
        },
      };
    }
    case SET_TRIGGERS_TABLE_THEMATICS: {
      return {
        ...state,
        temporaryTriggers: {
          ...state.temporaryTriggers,
          settings: {
            ...state.temporaryTriggers.settings,
            thematics: action.data,
          },
        },
      };
    }
    case SET_TRIGGERS_TABLE_CRITERIA_KEYS: {
      return {
        ...state,
        temporaryTriggers: {
          ...state.temporaryTriggers,
          settings: {
            ...state.temporaryTriggers.settings,
            criteriaKeys: action.data,
          },
        },
      };
    }
    case SET_ACTIVE_TRIGGERS_NUMBER_OF_MENTIONS: {
      return {
        ...state,
        activeTriggersNumberOfMentions: action.data,
      };
    }
    case SET_ACTIVE_TRIGGERS_ONLY_SELECTED_THEMATICS: {
      return {
        ...state,
        activeTriggersOnlySelectedThematics: action.data,
      };
    }
    case ADD_TRIGGERS_FILTERS: {
      return addTriggersFilters(state, action);
    }
    case REMOVE_TRIGGERS_FILTERS: {
      return removeTriggersFilters(state, action);
    }
    default: {
      return state;
    }
  }
};
