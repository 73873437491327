import { isEqual } from 'lodash';
import { removeOptions } from '../../_Components/Fields/NestedInputs/helpers/remove';

export const removeNormalFilter = (existingFilters, filterValue) =>
  existingFilters.filter(filter => !isEqual(filter, filterValue));

export const removeNormalIterationFilter = (existingFilters, filterValue) =>
  existingFilters.filter(filter => !isEqual(filter.option.id, filterValue.option.id));

export const removeThematicFilter = (existingFilters, filterValue, parentKey) =>
  removeOptions([filterValue], existingFilters, parentKey);

export const FILTERS_REMOVE_STRATEGIES = {
  thematic: removeThematicFilter,
  thematics: removeThematicFilter,
  criteriaKeys: removeThematicFilter,
  normal: removeNormalFilter,
  default: removeNormalFilter,
};
