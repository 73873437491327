import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveTriggersNumberOfMentions } from '_Resources/Triggers/Actions/Filters/setActiveTriggersNumberOfMentions';
import getHeaderMentionCountDataQuery from '_Resources/Header/Services/api/graphql/getHeaderMentionsCountData.js';
import { useQuery } from 'react-apollo';

type ThematicsType = {
  thematic: string;
  subThematic: string;
  subSubThematic: string;
};

type CriteriaKeysType = {
  name: string;
  values: Array<string>;
  id: string;
  label: string;
};

type QueryTriggersNumberOfMentionsType = {
  projectId: number | null;
  range: {
    startDate: string;
    endDate: string;
  };
  thematics: Array<ThematicsType>;
  criteriaKeys: Array<CriteriaKeysType>;
};

const QueryTriggersNumberOfMentions = ({
  projectId,
  range,
  thematics,
  criteriaKeys,
}: QueryTriggersNumberOfMentionsType) => {
  const dispatch = useDispatch();
  const { data } = useQuery(getHeaderMentionCountDataQuery, {
    variables: {
      projectId,
      range,
      thematics,
      criteriaKeys,
    },
    fetchPolicy: 'cache-first',
  });
  useEffect(() => {
    data && dispatch(setActiveTriggersNumberOfMentions(data.data.currentMentionCountWithoutNull));
  }, [data]);

  return null;
};

export default QueryTriggersNumberOfMentions;
