/* eslint-disable no-console  */
/* eslint-disable react/sort-comp */
import React, { useState, useEffect } from 'react';
import _, { get, uniq, uniqWith, uniqBy } from 'lodash';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import { getRoute } from '_Utils/routes/routeUtils';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import styled from 'styled-components';
import { HintsContent } from '_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import { withTranslation } from 'react-i18next';
import { setTemporaryTriggersTable } from '_Resources/Triggers/Actions/setTemporaryTriggersTable';
import { setTriggersTableAnalysisAxis } from '_Resources/Triggers/Actions/Filters/setTriggersTableAnalysisAxis';
import {
  getAnalysisAxisOptions,
  getCalculationCriteriaOptions,
  getItemsToDisplayOptions,
  getStateItemsToDisplay,
} from '_Resources/Triggers/Services/helpers';
import { setTriggersTableItems } from '_Resources/Triggers/Actions/Filters/setTriggersTableItems';
import { setTriggersTableCalculationCriteria } from '_Resources/Triggers/Actions/Filters/setTriggersTableCalculationCriteria';
import { setTriggersTableThematics } from '_Resources/Triggers/Actions/Filters/setTriggersTableThematics';
import { setTriggersTableCriteriaKeys } from '_Resources/Triggers/Actions/Filters/setTriggersTableCriteriaKeys';
import { transformThematicsToOptions } from '_Providers/QFilterOptionsProvider/optionProviderFunctions.js';
import QueryTriggersNumberOfMentions from 'Routes/Insights/Triggers/QueryTriggersNumberOfMentions';
import TriggersSettingsModal from './_Components/TriggersSubHeader/_Containers/ListTriggers/_Containers/TriggersSettingsModal/TriggersSettingsModal';
import TriggersDataContainer from './TriggersDataContainer';

const StyledContainer = styled(Container)`
  width: calc(100% - 2rem);
  margin: 0 1rem;
  position: relative;
  top: 1rem;
`;

type TriggersType = {
  t: Function;
  dispatchUpdateRoute: Function;
  dispatchSetTemporaryTriggersTable: Function;
  dispatchSetTriggersTableAnalysisAxis: Function;
  dispatchSetTriggersTableItems: Function;
  dispatchSetTriggersTableCalculationCriteria: Function;
  dispatchSetTriggersTableThematics: Function;
  dispatchSetTriggersTableCriteriaKeys: Function;
  activeTriggersTable: {
    id: number;
    title: string;
    description: string;
    isDefault: boolean;
    createdBy: {
      email: string;
      firstName: string;
      lastName: string;
    };
    settings: {
      analysisAxis: string;
      calculationCriteria: string;
      itemsToDisplay: {
        name: string;
        key: string;
      };
      significanceThreshold: number;
      range: {
        startDate: string;
        endDate: string;
      };
      thematics: {
        thematic: string;
        subThematic: string;
        subSubThematic: string;
      }[];
      criteriaKeys: {
        name: string;
        values: string[];
        id: string;
        label: string;
      }[];
    };
    data: {
      color: string;
      originalColor: string;
      name: string;
      originalName: string;
      items: {
        name: string;
        originalName: string;
        percentage: number;
        value: number;
      }[];
      value: number;
      deleted: boolean;
      masked: boolean;
    }[];
    customizations: {
      color: string;
      originalColor: string;
      name: string;
      originalName: string;
      items: {
        name: string;
        originalName: string;
      }[];
      deleted: boolean;
      masked: boolean;
    }[];
  };
  criteriaKeysOptions: {}[];
  thematicsFilters: {}[];
  currentProjectId: number | null;
};

const Triggers = (props: TriggersType) => {
  const [idpage, setIdPage] = useState('triggers');
  const [triggersSettingsModal, setTriggersSettingsModal] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    const { dispatchUpdateRoute } = props;
    getRoute(dispatchUpdateRoute, idpage);
  }, []);

  useEffect(() => {
    const {
      activeTriggersTable,
      dispatchSetTriggersTableThematics,
      dispatchSetTriggersTableCriteriaKeys,
      thematicsFilters,
    } = props;
    const initialThematics = activeTriggersTable?.settings?.thematics || [];
    const cleanInitialThematics = uniqWith(
      initialThematics.map(thematic =>
        Object.fromEntries(Object.entries(thematic).filter(([key, value]) => value != null)),
      ),
      _.isEqual,
    );
    const initialNITThematics = _.uniqWith(
      transformThematicsToOptions(cleanInitialThematics, thematicsFilters),
      _.isEqual,
    );
    dispatchSetTriggersTableThematics(initialNITThematics);

    const initialCriteriaKeys = activeTriggersTable?.settings?.criteriaKeys || [];

    const criteriaKeysCategories = uniq(initialCriteriaKeys.map(initialCriteriaKey => initialCriteriaKey.name));

    const criteriaKeysToReturn = [];
    criteriaKeysCategories.map(category => {
      initialCriteriaKeys.map(initialCriteriaKey => {
        if (category === initialCriteriaKey.name) {
          if (initialCriteriaKey.values.length === 1) {
            criteriaKeysToReturn.push({
              option: {
                id: initialCriteriaKey.values[0],
                label: initialCriteriaKey.values[0],
              },
              parents: [],
              filterCategory: initialCriteriaKey.name,
            });
          }
          if (initialCriteriaKey.values.length > 1) {
            initialCriteriaKey.values.forEach(value => {
              criteriaKeysToReturn.push({
                option: {
                  id: value,
                  label: value,
                },
                parents: [],
                filterCategory: initialCriteriaKey.name,
              });
            });
          }
        }
        return criteriaKeysToReturn;
      });
    });
    dispatchSetTriggersTableCriteriaKeys(criteriaKeysToReturn);
  }, [edit, props?.activeTriggersTable?.settings?.thematics]);

  const toggleTriggersTableSettingsModal = () => {
    const {
      dispatchSetTemporaryTriggersTable,
      activeTriggersTable,
      dispatchSetTriggersTableAnalysisAxis,
      dispatchSetTriggersTableItems,
      dispatchSetTriggersTableCalculationCriteria,
      dispatchSetTriggersTableThematics,
      dispatchSetTriggersTableCriteriaKeys,
      thematicsFilters,
      criteriaKeysOptions,
      t,
    } = props;
    if (!edit) {
      dispatchSetTemporaryTriggersTable(activeTriggersTable);
      const analysisAxisToDispatch = getAnalysisAxisOptions(t).find(
        option => option.value === activeTriggersTable.settings.analysisAxis,
      );
      dispatchSetTriggersTableAnalysisAxis(analysisAxisToDispatch);
      const itemsToDisplayVariable = activeTriggersTable.settings.itemsToDisplay;
      const itemsToDisplayArray = getItemsToDisplayOptions(criteriaKeysOptions, t);
      const stateItemsToDisplay = getStateItemsToDisplay(itemsToDisplayVariable, itemsToDisplayArray);
      dispatchSetTriggersTableItems(stateItemsToDisplay);
      const calculationCriteriaToDispatch = getCalculationCriteriaOptions(t).find(
        option => option.value === activeTriggersTable.settings.calculationCriteria,
      );
      dispatchSetTriggersTableCalculationCriteria(calculationCriteriaToDispatch);

      const initialThematics = activeTriggersTable.settings.thematics;

      const cleanInitialThematics = uniqWith(
        initialThematics.map(thematic =>
          Object.fromEntries(Object.entries(thematic).filter(([key, value]) => value != null)),
        ),
        _.isEqual,
      );
      const initialNITThematics = _.uniqWith(
        transformThematicsToOptions(cleanInitialThematics, thematicsFilters),
        _.isEqual,
      );
      dispatchSetTriggersTableThematics(initialNITThematics);
      const initialCriteriaKeys = activeTriggersTable.settings.criteriaKeys;

      const criteriaKeysCategories = uniq(initialCriteriaKeys.map(initialCriteriaKey => initialCriteriaKey.name));

      const criteriaKeysToReturn = [];
      criteriaKeysCategories.map(category => {
        initialCriteriaKeys.map(initialCriteriaKey => {
          if (category === initialCriteriaKey.name) {
            if (initialCriteriaKey.values.length === 1) {
              criteriaKeysToReturn.push({
                option: {
                  id: initialCriteriaKey.values[0],
                  label: initialCriteriaKey.values[0],
                },
                parents: [],
                filterCategory: initialCriteriaKey.name,
              });
            }
            if (initialCriteriaKey.values.length > 1) {
              initialCriteriaKey.values.forEach(value => {
                criteriaKeysToReturn.push({
                  option: {
                    id: value,
                    label: value,
                  },
                  parents: [],
                  filterCategory: initialCriteriaKey.name,
                });
              });
            }
          }
          return criteriaKeysToReturn;
        });
      });
      dispatchSetTriggersTableCriteriaKeys(criteriaKeysToReturn);
    }
    setTriggersSettingsModal(!triggersSettingsModal);
    setEdit(!edit);
  };

  const getThematicsVariable = () => {
    const { activeTriggersTable } = props;
    const { settings: activeTriggersSettings } = activeTriggersTable || {};
    const { thematics } = activeTriggersSettings;
    return uniqBy(thematics, _.isEqual);
  };

  const getCriteriaKeysVariable = () => {
    const { activeTriggersTable } = props;
    const { settings: activeTriggersSettings } = activeTriggersTable;
    const { criteriaKeys } = activeTriggersSettings;
    return criteriaKeys;
  };

  const { activeTriggersTable, t, currentProjectId } = props;
  const { data, title, description, settings: activeTriggersSettings, createdBy: triggersCreatedBy } =
    activeTriggersTable || {};
  const { range: activeTriggersTableRange } = activeTriggersSettings || {};
  const triggersSettingsOption = {
    text: 'Settings',
    onClick: () => {
      toggleTriggersTableSettingsModal();
    },
    icon: {
      iconType: 'INTERFACE_GEAR',
      iconColor: null,
    },
    topSeparator: false,
  };

  return (
    <div>
      <StyledContainer fluid className="p-0">
        {activeTriggersTable && activeTriggersTable?.id !== '' && (
          <>
            <QueryTriggersNumberOfMentions
              projectId={currentProjectId || null}
              range={activeTriggersTableRange}
              thematics={getThematicsVariable()}
              criteriaKeys={getCriteriaKeysVariable()}
            />
            <QcardPanel
              title={title}
              displayHints
              hints={[HintsContent.HINT_TRIGGERS_ANALYSIS_AXIS, HintsContent.HINT_TRIGGERS_ITEMS_TO_DISPLAY]}
              subtitleAdditionalContent={description}
              dropDownOptions={['EXPORT', 'EXPORT_TRIGGERS_DATA']}
              specificDropDownOptions={[triggersSettingsOption]}
              triggersCreatedBy={triggersCreatedBy}
              triggersSection
            >
              <TriggersDataContainer data={data} />
            </QcardPanel>
          </>
        )}
      </StyledContainer>
      <TriggersSettingsModal
        title={t('Edit this triggers table')}
        subtitle={t('You can change name')}
        isOpen={triggersSettingsModal}
        toggle={toggleTriggersTableSettingsModal}
        edit={edit}
        triggersTable={activeTriggersTable}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    eindexUnitFahrenheit: false,
    activeTriggersTable: get(state, ['triggers', 'activeTriggersTable']),
    criteriaKeysOptions: get(state, ['filters', 'projectFilters', 'criteriaKeys', 'normal']),
    thematicsFilters: get(state, ['filters', 'projectFilters', 'thematics']),
    currentProjectId: get(state, ['projects', 'currentProject', 'id']),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchUpdateRoute: newState => {
      dispatch(updateRoute(newState));
    },
    dispatchSetTemporaryTriggersTable: newState => {
      dispatch(setTemporaryTriggersTable(newState));
    },
    dispatchSetTriggersTableAnalysisAxis: newState => {
      dispatch(setTriggersTableAnalysisAxis(newState));
    },
    dispatchSetTriggersTableItems: newState => {
      dispatch(setTriggersTableItems(newState));
    },
    dispatchSetTriggersTableCalculationCriteria: newState => {
      dispatch(setTriggersTableCalculationCriteria(newState));
    },
    dispatchSetTriggersTableThematics: newState => {
      dispatch(setTriggersTableThematics(newState));
    },
    dispatchSetTriggersTableCriteriaKeys: newState => {
      dispatch(setTriggersTableCriteriaKeys(newState));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('triggers', 'card')(Triggers));
